import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../../src/components/layout/theme'

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <GoogleSearchConsoleTag />
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline>{props.children}</CssBaseline>
      </ThemeProvider>
    </React.Fragment>
  )
}

TopLayout.propTypes = {
  children: PropTypes.node,
}

// Search Console access tag for Viktor Ricci
const GoogleSearchConsoleTag = () => (
  <meta
    name="google-site-verification"
    content="nEylnr-_QC_hLw_BfeY-vWbALzZsHL7fbOlOG52lEqg"
  />
)
