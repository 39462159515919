exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-azubivideocontest-2023-tsx": () => import("./../../../src/pages/azubivideocontest2023.tsx" /* webpackChunkName: "component---src-pages-azubivideocontest-2023-tsx" */),
  "component---src-pages-berufsberatung-tsx": () => import("./../../../src/pages/berufsberatung.tsx" /* webpackChunkName: "component---src-pages-berufsberatung-tsx" */),
  "component---src-pages-packende-industrie-tsx": () => import("./../../../src/pages/packende-industrie.tsx" /* webpackChunkName: "component---src-pages-packende-industrie-tsx" */),
  "component---src-templates-ausbildung-details-tsx": () => import("./../../../src/templates/ausbildung-details.tsx" /* webpackChunkName: "component---src-templates-ausbildung-details-tsx" */),
  "component---src-templates-ausbildung-tsx": () => import("./../../../src/templates/ausbildung.tsx" /* webpackChunkName: "component---src-templates-ausbildung-tsx" */),
  "component---src-templates-azubis-einblick-berufe-tsx": () => import("./../../../src/templates/azubis-einblick-berufe.tsx" /* webpackChunkName: "component---src-templates-azubis-einblick-berufe-tsx" */),
  "component---src-templates-bewerbung-tsx": () => import("./../../../src/templates/bewerbung.tsx" /* webpackChunkName: "component---src-templates-bewerbung-tsx" */),
  "component---src-templates-bildungszentren-archiv-tsx": () => import("./../../../src/templates/bildungszentren-archiv.tsx" /* webpackChunkName: "component---src-templates-bildungszentren-archiv-tsx" */),
  "component---src-templates-bildungszentren-papier-verpackungsindustrie-tsx": () => import("./../../../src/templates/bildungszentren-papier-verpackungsindustrie.tsx" /* webpackChunkName: "component---src-templates-bildungszentren-papier-verpackungsindustrie-tsx" */),
  "component---src-templates-bildungszentrum-details-tsx": () => import("./../../../src/templates/bildungszentrum-details.tsx" /* webpackChunkName: "component---src-templates-bildungszentrum-details-tsx" */),
  "component---src-templates-blog-archiv-tsx": () => import("./../../../src/templates/blog-archiv.tsx" /* webpackChunkName: "component---src-templates-blog-archiv-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-custom-page-tsx": () => import("./../../../src/templates/custom-page.tsx" /* webpackChunkName: "component---src-templates-custom-page-tsx" */),
  "component---src-templates-einblick-details-tsx": () => import("./../../../src/templates/einblick-details.tsx" /* webpackChunkName: "component---src-templates-einblick-details-tsx" */),
  "component---src-templates-firmenkontakt-tsx": () => import("./../../../src/templates/firmenkontakt.tsx" /* webpackChunkName: "component---src-templates-firmenkontakt-tsx" */),
  "component---src-templates-fragen-zur-ausbildung-tsx": () => import("./../../../src/templates/fragen-zur-ausbildung.tsx" /* webpackChunkName: "component---src-templates-fragen-zur-ausbildung-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-karriere-details-tsx": () => import("./../../../src/templates/karriere-details.tsx" /* webpackChunkName: "component---src-templates-karriere-details-tsx" */),
  "component---src-templates-karriere-papier-verpackungsindustrie-tsx": () => import("./../../../src/templates/karriere-papier-verpackungsindustrie.tsx" /* webpackChunkName: "component---src-templates-karriere-papier-verpackungsindustrie-tsx" */),
  "component---src-templates-unternehmen-archiv-tsx": () => import("./../../../src/templates/unternehmen-archiv.tsx" /* webpackChunkName: "component---src-templates-unternehmen-archiv-tsx" */),
  "component---src-templates-unternehmen-details-tsx": () => import("./../../../src/templates/unternehmen-details.tsx" /* webpackChunkName: "component---src-templates-unternehmen-details-tsx" */),
  "component---src-templates-unternehmen-tsx": () => import("./../../../src/templates/unternehmen.tsx" /* webpackChunkName: "component---src-templates-unternehmen-tsx" */),
  "component---src-templates-vergleich-tsx": () => import("./../../../src/templates/vergleich.tsx" /* webpackChunkName: "component---src-templates-vergleich-tsx" */)
}

